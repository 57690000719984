import React from "react";
import { LandingLayout } from "../../../_metronic/layout";

import "../../../emigru/assets/sass/landing.scss"

function comingsoon() {
    return (
        <>
            <div className="col-12 col-md-8 vertical-center text-center text-md-left mb-10 mb-md-0 pr-md-10">
                <span>
                    <h1
                        className="display-1 font-weight-boldest text-dark-75 mb-10"
                    >
                        Coming soon...
                        </h1>
                </span>
            </div>
        </>
    )
}


export default function LandingPage() {
    return (
        <LandingLayout>
            <div className="landing d-flex flex-column flex-root">
                <div
                    className="hero row bgi-size-cover bgi-position-center bgi-no-repeat"
                >
                    {comingsoon()}
                </div>
            </div>
        </LandingLayout >
    );
}
