import React from 'react';
import * as typeformEmbed from '@typeform/embed'


export default class RequestDemoForm extends React.Component {
    constructor(props) {
        super(props);
        this.el = null;
    }

    componentDidMount() {
        if (this.el) {
            typeformEmbed.makeWidget(this.el, "https://emigru.typeform.com/to/m8W0ieIe", {
                hideFooter: true,
                hideHeaders: true,
                opacity: 100
            });
        }
    }

    render() {
        return (
            <div ref={(el) => this.el = el} style={{width: "80%", height: "500px"}} />
        )
    }
}
