/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
// import { Layout } from "../_metronic/layout";
// import BasePage from "./BasePage";
// import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Chatbot from "./modules/Landing/Chatbot";
import ComingSoon from "./modules/Landing/ComingSoon";

export function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={ComingSoon} />
            <Route exact path="/chatbot" component={Chatbot} />
            <Route path="/error" component={ErrorsPage} />
            <Redirect to="error/error-v1" />
        </Switch>
    );
}

//export function Routes() {
//    const {isAuthorized} = useSelector(
//        ({auth}) => ({
//            isAuthorized: auth.user != null,
//        }),
//        shallowEqual
//    );
//
//    return (
//        <Switch>
//            {!isAuthorized ? (
//                /*Render auth page when user at `/auth` and not authorized.*/
//                <Route>
//                    <AuthPage />
//                </Route>
//            ) : (
//                /*Otherwise redirect to root page (`/`)*/
//                <Redirect from="/auth" to="/"/>
//            )}
//
//            <Route path="/error" component={ErrorsPage}/>
//            <Route path="/logout" component={Logout}/>
//            <Route path="/landing" component={LandingPage}/>
//
//
//            {!isAuthorized ? (
//                /*Redirect to `/auth` when user is not authorized*/
//                <Redirect to="/auth/login"/>
//            ) : (
//                <Layout>
//                    <BasePage/>
//                </Layout>
//            )}
//        </Switch>
//    );
//}
//