import React from "react";
import SVG from "react-inlinesvg";
import {
    Button
} from "react-bootstrap";
import { LandingLayout } from "../../../_metronic/layout";
import { NavHashLink as NavLink } from "react-router-hash-link";
import RequestDemoForm from "../../../components/landing/RequestDemoForm";

import "../../../emigru/assets/sass/landing.scss"

function hero() {
    return (
        <>
            <div className="col-12 col-md-8 vertical-center text-center text-md-left mb-10 mb-md-0 pr-md-10">
                <span>
                    <h1
                        className="display-1 font-weight-boldest text-dark-75 mb-10"
                    >
                        Life in the US, Simplified.
                        </h1>
                    <h2
                        className="font-size-h2 font-weight-bold line-height-xl"
                    >
                        Our chatbot provides international students with an ecosystem of support, so they can focus on thriving.
                        </h2>
                </span>
            </div>
            <div className="col-12 col-md-4">
                <SVG
                    src="/emigru/media/chatbot_iphonex.svg"
                    className="img-fluid float-md-right"
                ></SVG>
            </div>
        </>

    )
}


function why_emigru() {
    const bookADemo = (
        <NavLink smooth to="/#book-a-demo">
            <Button variant="orange" size="lg">Book a Demo</Button>
        </NavLink>
    )
    const whyBots = (
        <NavLink smooth to="/#feature">
            <Button variant="outline-orange" size="lg">Why bots?</Button>
        </NavLink>
    )
    return (
        <>
            <div className="col-12 px-md-40 text-center text-md-left">
                <h2
                    className="font-weight-bolder text-white display-3"
                >
                    Why Emigru?
                        </h2>
                <div className="text-white mt-15 font-size-h3 line-height-xl">
                    <p>
                        The advice students get early on determines their ability to succeed. That’s why we’ve leveraged the experience of thousands of  immigrant students to create an ecosystem of knowledge and resources.
                    </p>
                    <p>
                        Our bot, Emi, delivers the right knowledge and tools to students at the right time. By tackling 80% of questions that advisors get from students, we free them to focus on the 20% that really matters.
                    </p>
                </div>
                <div className="col-12 pt-10 pl-0 d-none d-md-block">
                    <span className="pr-4">
                        {bookADemo}
                    </span>
                    <span className="pr-4 ml-10">
                        {whyBots}
                    </span>
                </div>
                <div className="col-12 pt-10 text-center d-md-none">
                    <div className="row">
                        <div className="col-12 mb-5 mb-md-0">
                            {bookADemo}
                        </div>
                        <div className="col-12">
                            {whyBots}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

function feature_1() {

    const data = [
        {
            headline: "Comprehensive.",
            content: (
                <>
                    "I wish I'd known earlier," said every foreign student ever. We did too. That’s why we built Emi to leading knowledge hub for internationals. Today, Emi knows about dozens of topics, far more than any university resource page.
                </>),
            icon: "chat_24px_outlined.svg",

        },
        {
            headline: "Personalized.",
            content: (
                <>
                    Generic answers don’t get students very far. That’s why Emi is built to provide tailored, context-specific answers. The best part? Emi gets smarter over time.
                </>
            ),
            icon: "ballot_24px_outlined.svg",
        },
        {
            headline: "Intuitive.",
            content: (
                <>
                    For us its not just about helping students synthesize complex information, it’s about creating trust. That's why simplicity, empathy, and transparency are at our core. The result? Emi saves students headaches, and frees university advisors to focus on what really matters.
                </>
            ),
            icon: "mobile_friendly_24px_outlined.svg",
        },
        {
            headline: "Secure.",
            content: (
                <>
                    Founded by a security engineer and a privacy zealot, we don’t just ‘add’ a couple security features; instead, we designed Emigru’s architecture around keeping data safe. We encrypt chats and comply with FERPA, GDPR, & CCPA.
                </>
            ),
            icon: "lock_24px_outlined.svg",
        },
    ]

    return (
        <>
            <div className="headline col-12 text-center text-md-left">
                <p className="mb-2 font-weight-bold display-4 d-none d-md-block">
                    A new life comes with new questions.
                </p>
                <p className="font-weight-bold display-4 d-none d-md-block">
                    Emi, our smart bot, has the answers.
                </p>
                <p className="font-weight-bold font-size-h2 d-md-none">
                    A new life comes with new questions.
                    Emi, our smart bot, has the answers.
                </p>
            </div>
            {data.map(element => (
                <div className="col-12 col-md-6" key={element.headline}>
                    <div className="mr-md-20">
                        <h3
                            className=" font-weight-bolder display-4 text-dark-75 mt-15"
                        >
                            {element.headline}
                        </h3>
                        <img className="icon" alt={element.headline + "-icon"} src={"/emigru/media/" + element.icon} />
                        <div className="font-size-h5 text-dark-75 mt-10">
                            <p>
                                {element.content}
                            </p>
                        </div>
                    </div>
                </div>

            ))}
        </>
    )
}

function feature_2() {
    const data = [
        "Travel guidelines",
        "Visa application process",
        "Work authorization",
        "Maintaining status",
        "Implications of picking a major",
        "Driver’s license",
        "SSN & ITIN",
        "Getting a phone",
        "Emergency numbers",
        "Transportation",
        "Finding an international community",
        "Health insurance",
        "Finding a job as an international",
        "Taxes",
        "Banking",
        "Credit cards",
        "Investing",
        "Legal resources",
    ]


    return (
        <>
            <div className="vertical-center col-12 col-md-4 mb-10 mb-md-0">
                <span>
                    <h2 className="font-weight-bolder font-size-h2 line-height-xl text-white pb-5">
                        It’s hard to be an expert in most topics. Unless you're powered by AI.
                        </h2>
                    <NavLink smooth to="/#book-a-demo">
                        <Button variant="orange">Book a demo</Button>
                    </NavLink>
                </span>
            </div>
            <div className="col-12 col-md-8">
                <p className="font-weight-bolder font-size-h4 text-white mb-5">
                    Just some of the things Emi knows about...
                </p>
                <ul>
                    {data.map(element => (
                        <li className="text-white font-size-h6" key={element}>
                            {element}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

// function partner() {
//     return (
//         <>
//             <div className="col-12">
//                 <h2 className="font-weight-bolder text-dark-75">
//                     Our partner ecosystem
//                         </h2>
//             </div>
//             <div className="col-12">
//                 To serve community, we’ve partnered with the best resources, from banking to job opportunities, to make foreign student’s lives easier.
//             </div>
//         </>
//     );
// }

function join_today() {
    const joinToday = (
        <NavLink smooth to="/#book-a-demo">
            <Button variant="orange">Join Today</Button>
        </NavLink>
    )
    const contactUs = <Button variant="outline-orange" target="_blank" href="https://emigru.typeform.com/to/m8W0ieIe">Contact Us</Button>

    return (
        <>
            <div className="col-12 col-md-6 font-size-h2 align-self-center mb-10 mb-md-0">
                <p className="mb-0">
                    We improve Emigru by <strong>listening to our community</strong>.
                    Send us a life hack or sign up for our newsletter!
                </p>
            </div>
            <div className="vertical-center col-6 d-none d-md-block text-center align-self-center">
                <span className="mr-10">
                    {joinToday}
                </span>
                <span>
                    {contactUs}
                </span>
            </div>
            <div className="col-12 text-center align-self-center d-md-none">
                <div className="row">
                    <div className="col-12 mb-5">
                        {joinToday}
                    </div>
                    <div className="col-12">
                        {contactUs}
                    </div>
                </div>
            </div>
        </>
    )
}

function ready_for_demo() {
    return (
        <>
            <div className="col-12">
                <h2 className="font-weight-bolder text-dark-75 text-center display-3">
                    Ready for demo?
                        </h2>
            </div>
            <div className="col-12 text-center mt-10 font-size-h4">
                <p>
                    Let’s connect! Send us a quick message and our team will get back to you.
                </p>
            </div>

            <div className="col-12 mt-10" align="center">
                <RequestDemoForm />
            </div>
        </>
    )
}

export default function LandingPage() {
    return (
        <LandingLayout>
            <div className="landing d-flex flex-column flex-root">
                <div
                    className="hero row bgi-size-cover bgi-position-center bgi-no-repeat"
                >
                    {hero()}
                </div>
                <div
                    className="section bg-full bg-dark-blue row d-flex flex-wrap flex-center"
                >
                    {why_emigru()}
                </div>
                <div
                    id="feature"
                    className="section feature-1 row d-flex flex-wrap"
                >
                    {feature_1()}
                </div>
                <div
                    className="section feature-2 bg-full bg-dark-blue row d-flex flex-wrap"
                >
                    {feature_2()}
                </div>
                {/*
                    <div
                        className="partner row d-flex flex-wrap mt-15 mb-15"
                    >
                        {partner()}
                    </div>
                */
                }
                <div
                    className="section join-today row d-flex flex-wrap"
                >
                    {join_today()}
                </div>
                <div
                    id="book-a-demo"
                    className="section row d-flex flex-wrap pt-0"
                >
                    {ready_for_demo()}
                </div>
            </div>
        </LandingLayout >
    );
}
 
